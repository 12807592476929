import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'intro',
    loadChildren: () =>
      import('./pages/intro/intro.module').then((m) => m.IntroPageModule),
  },
  {
    path: 'completion',
    loadChildren: () =>
      import('./pages/completion/completion.module').then(
        (m) => m.CompletionPageModule
      ),
  },
  {
    path: 'composition',
    loadChildren: () =>
      import('./pages/composition/composition.module').then(
        (m) => m.CompositionPageModule
      ),
  },
  {
    path: 'result',
    loadChildren: () => import('./pages/result/result.module').then( m => m.ResultPageModule)
  },
  {
    path: 'instrument-selection',
    loadChildren: () => import('./pages/instrument-selection/instrument-selection.module').then( m => m.InstrumentSelectionPageModule)
  },
  {
    path: 'composer-title-write',
    loadChildren: () => import('./pages/composer-title-write/composer-title-write.module').then( m => m.ComposerTitleWritePageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
